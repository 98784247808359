/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"
import { Button } from "react-bootstrap"
import withFocusTrap from "@ninjaone/components/src/hoc/withFocusTrap"
import {
  localized,
  renderReactComponentIfElementExists,
  unmountReactComponentIfElementExists,
} from "js/includes/common/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { css } from "@emotion/react"

const style = css`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000;
`

const containerStyle = css`
  top: 35%;
  color: white;
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
`

const buttonsSectionStyle = css`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-end;
`

const containerMiddleStyle = css`
  position: relative;
  left: 20%;
  width: 60%;
`

const titleStyle = css`
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 300px;
`

const buttonStyle = {
  color: "black",
  marginRight: 7,
  paddingLeft: 15,
  paddingRight: 15,
  fontSize: 14,
  fontWeight: "bold",
}

const containerId = "message-dialog"

export class MessageDialog extends PureComponent {
  state = {
    id: "",
    isValid: false,
  }

  componentWillUnmount() {
    this.props.resolve(this.state.id)
  }

  getIconColorClass() {
    const { icon } = this.props
    if (!icon) return ""

    switch (icon.type) {
      case "info":
        return "txt-color-blue"
      case "warning":
        return "txt-color-yellow"
      case "critical":
        return "txt-color-redLight"
      default:
        throw new Error(`Invalid icon type: "${icon.color}"`)
    }
  }

  getButtonColorForClass(type) {
    if (!type) return "btn-white"

    switch (type) {
      case "link":
        return "btn-link"
      case "primary":
        return "btn-primary"
      case "critical":
        return "btn-danger"
      default:
        throw new Error(`Invalid button type: "${type}"`)
    }
  }

  localizeIfString = message => {
    switch (typeof message) {
      case "string":
        return localized(message)
      case "function":
        return message()
      default:
        throw new Error(`Invalid type of message ${typeof message} `)
    }
  }

  reconcile = id => {
    this.setState({ id }, () => {
      unmountReactComponentIfElementExists(document.getElementById(containerId))
    })
  }

  setValidation = isValid => {
    this.setState({ isValid })
  }

  render() {
    const {
      title,
      message,
      MessageComponent,
      note,
      buttons,
      icon: { icon, spin },
    } = this.props

    return (
      <div css={style}>
        <div css={containerStyle}>
          <div css={containerMiddleStyle}>
            <span css={titleStyle}>
              {icon && (
                <span className={`${this.getIconColorClass()}`} style={{ marginRight: 10, display: "inline-block" }}>
                  <FontAwesomeIcon {...{ icon, spin }} />
                </span>
              )}
              {this.localizeIfString(title)}
            </span>

            {message && <p>{this.localizeIfString(message)}</p>}

            {note && (
              <p>
                <b>{localized("Note:").toUpperCase()}</b>
                &nbsp;
                {this.localizeIfString(note)}
              </p>
            )}

            {MessageComponent && <MessageComponent isValid={this.state.isValid} setValidation={this.setValidation} />}

            <div css={buttonsSectionStyle}>
              {buttons?.map(({ id, label, type, validate }) => (
                <Button
                  onClick={() => this.reconcile(id)}
                  className={`${this.getButtonColorForClass(type)}`}
                  style={buttonStyle}
                  key={id}
                  disabled={validate && !this.state.isValid}
                >
                  {this.localizeIfString(label)}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const MessageDialogWithFocusTrap = withFocusTrap(MessageDialog)

export default function _MessageDialog(props) {
  return new Promise(resolve => {
    renderReactComponentIfElementExists(
      <MessageDialogWithFocusTrap {...{ ...props, resolve }} />,
      document.getElementById(containerId),
    )
  })
}
